<!--
 * @Author: your name
 * @Date: 2021-02-05 11:11:18
 * @LastEditTime: 2021-02-06 20:18:55
 * @LastEditors: Please set LastEditors
 * @Description: 汇总
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotData\inventorySummary\index.vue
-->
<template>
  <!-- NAME[epic=库管] 库存变化汇总 -->

  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            placeholder="请选择品类"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Category"
              :key="index"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            style="width: 160px"
            @brand-select-change="brandChange"
          ></brand-select>
          <!-- <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item prop="keyword">
          <!--          <el-input
            v-model="form.keyword"
            placeholder="商品名称，简拼"
          ></el-input>-->
          <goods-search
            ref="goodsSearch"
            :is-table="false"
            :popper-class="'select-idx selectDC'"
            :goods-list-obj="{ is_set: 1 }"
            @input-goods="setkeyword"
            @select-search-goods="setkeyword"
          ></goods-search>
        </el-form-item>

        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item prop="inout_class">
          <el-select
            v-model="form.inout_class"
            placeholder="出入库类型"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in typecov"
              :key="index"
              :label="item.type_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="allot_code">
          <el-input v-model="form.allot_code" placeholder="单据号"></el-input>
        </el-form-item> -->
        <el-form-item prop="price_type">
          <el-select
            v-model="form.price_type"
            placeholder=""
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column align="center" width="50">
          <!-- <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template> -->
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    components: { BrandSelect, GoodsSearch },
    data() {
      return {
        form: {
          depot_id: '', //仓ID
          Time: [],
          start_time: '', //开始时间 如2020-10-1
          end_time: '', //结束时间 如2020-12-31
          brand_id: '', //品牌ID
          class_id: '', //品类ID
          keyword: '', //产品关键字 简拼
          pageSize: 10,
          pageNo: 1,
          price_type: 1, //价格类型
        },
        total: 0,
        warehouse: [],
        Category: [],
        Brand: [],
        Typesof: [
          {
            id: 1,
            name: '默认单位销售价',
          },
          {
            id: 2,
            name: '默认单位成本价',
          },
          // {
          //   id: 3,
          //   name: '默认单位进价',
          // },
          // {
          //   id: 4,
          //   name: '默认单位售价',
          // },
          {
            id: 5,
            name: '大包装成本价',
          },
          // {
          //   id: 6,
          //   name: '大包装金进价',
          // },
        ],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Category: '/baseAdmin/common/goods-class-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/depotAdmin/bill-allot/stock-summary-list',
        },
        tableData: [],
        checkList: [
          '品牌',
          '商品编码',
          '商品名称',
          '规格',
          '条形码（默认包装）',
          '单位换算',
          '单价',
          '期初数量',
          '期初金额',
          '期末数量',
          '期末金额',
          '采购',
          '采退',
          '调入',
          '调出',
          '销售',
          '退货',
          '盘盈',
          '盘亏',
          '报损',
          '组装',
          '拆出',
          '入库',
          '出库',
        ],
        colemd: [
          {
            order: 1,
            label: '品牌',
            align: 'center',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 1.5,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '',
          },
          {
            order: 4,
            label: '条形码（默认包装）',
            align: 'center',
            prop: 'unit_code',
            width: '',
          },
          {
            order: 5,
            label: '单位换算',
            align: 'center',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 6,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 7,
            label: '出库合计',
            align: 'center',
            prop: 'out_total',
            width: '',
          },
          {
            order: 7,
            label: '入库合计',
            align: 'center',
            prop: 'in_total',
            width: '',
          },
          {
            order: 7,
            label: '期初数量',
            align: 'center',
            prop: 'qichu_count',
            width: '',
          },
          {
            order: 7,
            label: '变化数量',
            align: 'center',
            prop: 'change_total',
            width: '',
          },
          {
            order: 7,
            label: '期末数量',
            align: 'center',
            prop: 'qimo_count',
            width: '',
          },
          {
            order: 7,
            label: '期初金额',
            align: 'center',
            prop: 'qichu_money',
            width: '',
          },
          {
            order: 7,
            label: '期末金额',
            align: 'center',
            prop: 'qimo_money',
            width: '',
          },
          {
            order: 7,
            label: '-销售',
            align: 'center',
            prop: 'xiaoshou_out',
            width: '',
          },
          {
            order: 7,
            label: '-调出',
            align: 'center',
            prop: 'diaobo_out',
            width: '',
          },
          {
            order: 7,
            label: '-盘亏',
            align: 'center',
            prop: 'pandian_out',
            width: '',
          },
          {
            order: 7,
            label: '-其他出库',
            align: 'center',
            prop: 'qita_out',
            width: '',
          },
          {
            order: 7,
            label: '-报损',
            align: 'center',
            prop: 'baosun_out',
            width: '',
          },
          {
            order: 7,
            label: '-采购退货',
            align: 'center',
            prop: 'caigou_out',
            width: '',
          },
          {
            order: 7,
            label: '-拆出',
            align: 'center',
            prop: 'chaizhuang_out',
            width: '',
          },
          {
            order: 7,
            label: '+退货',
            align: 'center',
            prop: 'xiaoshou_in',
            width: '',
          },
          {
            order: 7,
            label: '+采购',
            align: 'center',
            prop: 'caigou_in',
            width: '',
          },
          {
            order: 7,
            label: '+调入',
            align: 'center',
            prop: 'diaobo_in',
            width: '',
          },
          {
            order: 7,
            label: '+其他入库',
            align: 'center',
            prop: 'qita_in',
            width: '',
          },
          {
            order: 7,
            label: '+盘盈',
            align: 'center',
            prop: 'pandian_in',
            width: '',
          },
          {
            order: 7,
            label: '+报溢',
            align: 'center',
            prop: 'baosun_in',
            width: '',
          },
          {
            order: 7,
            label: '+组装',
            align: 'center',
            prop: 'chaizhuang_in',
            width: '',
          },

          // {
          //   order: 6,
          //   label: '出库合计',
          //   align: 'center',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 6,
          //   label: '入库合计',
          //   align: 'center',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 7,
          //   label: '期初数量',
          //   align: 'center',
          //   prop: 'qichu_count',
          //   width: '',
          // },
          // {
          //   order: 6,
          //   label: '变化数量',
          //   align: 'center',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 6,
          //   label: '期末数量',
          //   align: 'center',
          //   prop: 'qimo_count',
          //   width: '',
          // },
          // {
          //   order: 8,
          //   label: '期初金额',
          //   align: 'center',
          //   prop: 'qichu_money',
          //   width: '',
          // },
          // {
          //   order: 10,
          //   label: '期末金额',
          //   align: 'center',
          //   prop: 'qimo_money',
          //   width: '',
          // },
          // {
          //   order: 11,
          //   label: '采购',
          //   align: 'center',
          //   prop: 'caigou_in',
          //   width: '',
          // },
          // {
          //   order: 12,
          //   label: '采退',
          //   align: 'center',
          //   prop: 'caigou_out',
          //   width: '',
          // },
          // {
          //   order: 13,
          //   label: '调入',
          //   align: 'center',
          //   prop: 'diaobo_in',
          //   width: '',
          // },
          // {
          //   order: 14,
          //   label: '调出',
          //   align: 'center',
          //   prop: 'diaobo_out',
          //   width: '',
          // },
          // {
          //   order: 15,
          //   label: '销售',
          //   align: 'center',
          //   prop: 'xiaoshou_out',
          //   width: '',
          // },
          // {
          //   order: 16,
          //   label: '退货',
          //   align: 'center',
          //   prop: 'xiaoshou_in',
          //   width: '',
          // },

          // {
          //   order: 17,
          //   label: '盘盈',
          //   align: 'center',
          //   prop: 'pandian_in',
          //   width: '',
          // },
          // {
          //   order: 18,
          //   label: '盘亏',
          //   align: 'center',
          //   prop: 'pandian_out',
          //   width: '',
          // },
          // {
          //   order: 19,
          //   label: '报损',
          //   align: 'center',
          //   prop: 'baosun_out',
          //   width: '',
          // },
          // {
          //   order: 20,
          //   label: '组装',
          //   align: 'center',
          //   prop: 'chaizhuang_in',
          //   width: '',
          // },
          // {
          //   order: 21,
          //   label: '拆出',
          //   align: 'center',
          //   prop: 'chaizhuang_out',
          //   width: '',
          // },
          // {
          //   order: 22,
          //   label: '入库',
          //   align: 'center',
          //   prop: 'qita_in',
          //   width: '',
          // },
          // {
          //   order: 32,
          //   label: '出库',
          //   align: 'center',
          //   prop: 'qita_out',
          //   width: '',
          // },
        ],
        footer: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerCategory()
      this.handlerBrand()
      this.handlerlist()
    },
    methods: {
      setkeyword(val) {
        this.form.keyword = val
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          sums[9] = '期初总和'
          sums[10] = this.footer.qichu_money
          sums[11] = '期末总和'
          sums[12] = this.footer.qimo_money

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品类
      handlerCategory() {
        postAction(this.url.Category, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品类')
            this.Category = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },

      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.$refs.brandSelect.resetForm()
        this.handlerlist()
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)

            this.tableData = res.data.rows

            // this.tableData.push({
            //   allot_code: '本页合计',
            //   in_count: res.data.footer_page.in_count,
            //   out_count: res.data.footer_page.out_count,
            //   balance_count: res.data.footer_page.balance_count,
            // })
            this.total = res.totalCount
            this.footer = res.data.footer
          })
          .catch((err) => {
            console.log(err)
          })
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      handlerExport() {
        downloadFile(
          '/depotAdmin/bill-allot/export-stock-summary-list',
          '库存变化汇总表.xlsx',
          this.form
        )
      },
    },
  }
</script>

<style></style>
