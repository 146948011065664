var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.warehouse, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "class_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择品类" },
                      model: {
                        value: _vm.form.class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "class_id", $$v)
                        },
                        expression: "form.class_id",
                      },
                    },
                    _vm._l(_vm.Category, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.class_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "brand_id" } },
                [
                  _c("brand-select", {
                    ref: "brandSelect",
                    staticStyle: { width: "160px" },
                    attrs: { duoxuan: true },
                    on: { "brand-select-change": _vm.brandChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("goods-search", {
                    ref: "goodsSearch",
                    attrs: {
                      "is-table": false,
                      "popper-class": "select-idx selectDC",
                      "goods-list-obj": { is_set: 1 },
                    },
                    on: {
                      "input-goods": _vm.setkeyword,
                      "select-search-goods": _vm.setkeyword,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "Time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.Time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "Time", $$v)
                      },
                      expression: "form.Time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "price_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.form.price_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "price_type", $$v)
                        },
                        expression: "form.price_type",
                      },
                    },
                    _vm._l(_vm.Typesof, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersver },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v("重 置")]
                  ),
                  _c("el-button", { on: { click: _vm.handlerExport } }, [
                    _vm._v("导 出"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "summary-method": _vm.getSummaries,
                "show-summary": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        row.bill_code == "合计"
                          ? _c("div")
                          : _c("div", [_vm._v(_vm._s($index + 1))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }